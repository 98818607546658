/********************** NEO AUTOCOMPLETE START **********************/

ul {
    list-style:none;
}

.neo-autocomplete-wrap:not(.absolute) {
	position:relative;
}

.neo-autocomplete-wrap.search:before {
	display:block;
	content:'';
	background:url(sprite.png) -127px 0px;
	width:16px;
	height:16px;
	position:absolute;
	top:8px; left:9px;
	opacity:0.9;
}

.neo-autocomplete-content {
	max-height:250px;
	overflow:auto;
    margin-bottom:0;
    padding: 0;
}

.neo-autocomplete-list {
	position:absolute;
	background:#fff;
	width:100%;
	box-shadow:1px 1px 2px #ccc;
	border-radius:4px;
	overflow:hidden;
}

.neo-autocomplete-content > li {
	padding:6px 8px;
	cursor:pointer;
}

.neo-autocomplete-content > li.selected,
.neo-autocomplete-content > li.selected .post-wrap,
.neo-autocomplete-search:hover {
	background:$primary;
	color:#fff;
}

.neo-autocomplete-content > li:hover .post-wrap {
	text-decoration:none;
}

.neo-autocomplete-search {
	display:block;
	padding:12px;
	font-weight:bold;
	text-decoration:none;
	border-bottom:1px solid #ccc;
}

.neo-autocomplete-search:hover {
	text-decoration:none;
}
