@keyframes loading-spinner {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

#neo-loading {
  position: fixed;
  background: $white;
  padding: 15px 25px;
  box-shadow: 1px 1px 5px #ccc;
  border-radius: 25px; }

.loading-area {
  display: block;
  overflow: hidden;
  padding-bottom: 1px;
  width: 58px;
  margin: 0 auto; }

.loading-circle {
  display: block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid $primary;
  border-top: 3px solid $white;
  margin: 0 auto;
  animation: loading-spinner 1.3s infinite linear; }
